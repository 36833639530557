   
.green-bar {
    background-color: #FEA127; /* Color of the green bar */
    height: 50px; /* Height of the green bar */
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 21px;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Styles for the body */
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    line-height: 1.5;
    background-color: #000;
    color: #fff;
    margin: 0;
    padding: 0;
  }
  
  /* Styles for the heading */
  h1 {
    font-family: 'Imbue', sans-serif;
    text-transform: uppercase;
    font-size: 6rem;
    font-weight: 600;
    text-align: center;
    position: relative;
  }
  
  /* Styles for the interjection in the heading */
  h1 .interjection {
    position: absolute;
    bottom: 8.5rem;
    margin-left: -2.65rem;
    font-size: 2rem;
    letter-spacing: 0;
    font-family: 'Rock Salt', cursive;
  }
  
  /* Styles for the interjection after the heading */
  h1 .interjection:after {
    content: 'v';
    position: absolute;
    top: 2.5rem;
    font-size: 1.5rem;
    left: 2rem;
    font-weight: 800;
    transform: scaleY(0.5);
  }
  
  /* Styles for the table responsiveness */
  .table-responsive {
    width: 100%;
    margin: 0;
    overflow-x: auto;
  }
  
  /* Styles for the table on larger screens */
  @media (min-width: 600px) {
    .table-responsive {
      width: 800px;
      margin: 2em auto;
    }
  }
  
  /* Styles for the table */
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #313132;
    border: 2px solid #dcdcdc; /* Added border for the table */
  }
  
  /* Styles for the table header */
  th {
    text-align: right;
    padding: 0.5em 2em;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #dcdcdc;
    background-color: #313132;
  }
  
  /* Styles for the table cells */
  td {
    text-align: right;
    padding: 0.5em 2em;
    font-family: "Courier New", Courier, monospace;
    font-size: 24px;
    font-weight: semi-bold;
    color: #FDA02B;
  }
  
  /* Styles for the second column in the table */
  td:nth-child(2) {
    color: #BEBEBE;
  }
  
  /* Styles for the first column in the table */
  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
  }
  
  /* Styles for odd rows in the table */
  tr:nth-child(odd) {
    background: #000000;
  }
  
  .increase .change, .increase .percentage {
    color: #00c73c;
  }
  
  .decrease .change, .decrease .percentage {
    color: red;
  }
  
  .header {
    text-align: center;
    padding: 3px;
    padding-top: 1%;
  }
  
  .logo {
    display: inline-block;
    width: 30vw;
    max-width: 900px;
  }
  
  .logo img {
    width: 100%;
    /* animation-name: shake; */
    border-radius: 10%;
    /* animation-duration: 2s;
    animation-iteration-count: infinite; */
  }
  
  @media (min-width: 600px) {
    .logo {
      width: 100%;
    }
  }
  
  @media (min-width: 1024px) {
    .logo {
      width: 30vw;
    }
  }
  
  /* @keyframes shake {
      5%, 15%, 25%, 35%, 55%, 65%, 75%, 95% {
        filter: blur(0.2px);
        transform: translateY(1px) rotate(0deg);
      }
  
      10%, 30%, 40%, 50%, 70%, 80%, 90% {
        filter: blur(0.2px);
        transform: translateY(-1px) rotate(0deg);
      }
  
      20%, 60% {
        filter: blur(0px);
        transform: translate(-1px, 1px) rotate(0deg);
      }
  
      45%, 85% {
        filter: blur(0.2px);
        transform: translate(1px, -1px) rotate(0deg);
      }
  
      100% {
        filter: blur(0px);
        transform: translate(0) rotate(-1deg);
      }
    }
     */
  .connect-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  margin-bottom: 100px;
  }
  
  .connect-button {
  padding: 15px 25px;
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 5px;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }
  
  .connect-button:hover {
  background-color: #333333;
  }
  
  h3 {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
  margin-top: 2rem;
  color: #FEA127; /* Color for the heading */
  }
  
  h4 {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-size: 2rem; /* Slightly smaller than h3 */
  font-weight: 600;
  margin-top: 1.5rem;
  color: #fff; /* Complementary color to h3 */
  margin-left: auto;
  margin-right: auto;
  width: 800px; /* Adjust to match the width of your tables */
  text-align: left;
  }
  
  @media (max-width: 600px) {
  h4 {
    width: 100%; /* Ensure it resizes properly on smaller screens */
    padding-left: 1em; /* Optional: add some padding for better readability */
  }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
    10% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    20% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    100% {
      transform: translate(1px, 1px) rotate(0deg);
    }
  }
  
  .shake-animation {
    animation: shake 0.5s infinite;
  }
  